/*
 * ====================================================================
 * Grow Template
 * @package  Grow Template
 * @author   GrowGroup.Inc <info@grow-group.jp>
 * @license  MIT Licence
 * ====================================================================
 */

(function ($) {

    var growApp = function () {
    };


    $(function () {
        var app = new growApp();

    });

})(jQuery);
